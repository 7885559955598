<template>
  <div class="history-wrapper page">
    <data-table
      :data="getData"
      hide-box-control
      hide-create
      hide-remove
      hide-change
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DataTable from '@/components/dataTable'

export default {
  name: 'History',
  components: {
    DataTable
  },
  computed: {
    ...mapGetters('history', ['getData']),
    ...mapGetters('settings', ['getDefaultValues'])
  },
  async created () {
    await this.loadData()
  },
  methods: {
    ...mapActions('user', { loadDataUser: 'loadData' }),
    ...mapActions('settings', { loadDataSettings: 'loadData' }),
    ...mapActions('history', { loadDataHistory: 'loadData' }),
    async loadData () {
      // TODO: Подумать над оптимизацией загрузки данных НА ВСЕХ стр.
      await this.$bus.emit('start-loader')
      await this.loadDataUser()
      await this.loadDataSettings()
      await this.loadDataHistory()
      await this.$nextTick()
      await this.$bus.emit('stop-loader')
    }
  }
}
</script>
